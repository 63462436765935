<template>
  <div class="page-content">
    <div class="page-content-inner">
      <div class="section-header pb-0 mb-4">
        <div class="section-header-left">
          <h2 class="mb-4"> {{ item.name }}</h2>
          <nav class="responsive-tab mt-4">
            <li class="uk-active"><a @click="goBackRouter"><i class="uil-arrow-left"></i>
                {{ $t('certification.all_certification') }}</a></li>
          </nav>
        </div>
        <div class="section-header-right">
          <nav class="responsive-tab mt-4">
            <li v-if="item.shareable" class="uk-active">
              <button class="btn btn-animated btn-success btn-animated-x uk-first-column m-2 btn-sm" type="button"
                @click="handleToDownloadPdf">
                <span class="btn-inner--visible"><i class="uil-file-download"></i> {{ $t('certification.pdf') }}</span>
                <span class="btn-inner--hidden">
                  <i class="uil-cloud-download"></i>
                </span>
              </button>
            </li>
          </nav>
        </div>
      </div>
      <div uk-grid>
        <div class="uk-width-5-5@m">
          <div class="certificate-container">
            <div id="image-holder" :style=img_section_style class="certificate">
              <div class="water-mark-overlay"></div>
              <div class="certificate-header">
                <img :src="companyLogo" alt="" class="logo" style="object-fit: cover">
              </div>
              <div class="certificate-body">
                <p class="certificate-title"><strong> {{ item.name }}</strong></p>
                <h1> {{ item.certifying_title }}</h1>
                <p class="student-name pt-4">
                  <span>{{ $t('certification.mr') }}</span>
                  {{ item.user[0].fullname }}
                </p>
                <div class="certificate-content">
                  <div class="about-certificate pt-0">
                    <p>
                      <b>{{ item.content.title }}</b> {{ $t('certification.completed_eduction') }} <b>
                        {{ fixDate(item.completed_at) }}</b>
                      {{ $t('certification.date_eduction') }}
                    </p>
                  </div>
                </div>
                <div class="certificate-footer">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <p>{{ item.certifying_name }}</p>
                      <span>{{ item.certifying_title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import { GET_ITEM_DETAIL_BY_ID } from "@/core/services/store/REST.module";
import moment from "moment";
import 'moment/locale/tr';
import domtoimage from 'dom-to-image';

export default {
  name: "CertificatesPreview",
  components: {
    DashboardLayout

  },
  data() {
    return {
      certificationUrl: 'api/certification/',
      pdfUrl: 'localhost:8000/api/pdf/',
      item: [],

    };
  },
  methods: {
    DownloadImage(elementId = 'image-holder', filename = 'download') {
      domtoimage.toBlob(document.getElementById(elementId))
        .then(function (blob) {
          let saveBlob = (function () {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            return function (blob, fileName) {
              let url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          }());

          saveBlob(blob, filename + '.jpg');
        });
    },
    handleToDownloadPdf() {
      window.open(this.item.certificate_pdf_link, '_blank')
    },
    goBackRouter() {
      this.$router.go(-1);
    },
    fixDate(value) {
      return moment(value).locale('tr').format('LL');
    },
  },
  computed: {
    companyLogo() {
      return this.item.company ? this.item.company.logo_link : '';
    },
    img_section_style: function () {
      let bgImg = this.item && this.item.hasOwnProperty('template') && this.item.template ? this.item.template.cover_image_link : '';
      return {
       // 'width': '100%',
        'background': 'url(' + bgImg + ')',
       // 'background-repeat': 'no-repeat',
        //'background-size': 'cover',
        //'height': '650px',
        //'border-color': '#cdd1d4 !important'
      }
    },
  },
  mounted() {
    let certificationId = this.$route.params.id;
    if (certificationId) {
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: this.certificationUrl + certificationId + '/preview',
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          this.item = result.data;
        }
      })
    } else {
      this.$router.go(-1);
    }
  },
}
</script>

<style scoped></style>
